<template>
  <div class="editOrderTagContainer" element-loading-text=""
       element-loading-background="rgba(0, 0, 0, 0.3)"
       v-loading.fullscreen.lock="fullscreenLoading">
    <div v-if="fullscreenLoading===false">
      <div class="optionHead">
        <ul class="flexAndCenter color333 fontSize14">
          <li class="tagType">{{ $fanyi("类别") }}</li>
          <li class="tagEncode">{{ $fanyi("标签号") }}</li>
          <li class="tagEncode">{{ $fanyi("商品码") }}</li>
          <li class="oldTag flexAndCenterAndCenter">
            <div>{{ $fanyi("历史标签") }}</div>
            <el-popover trigger="hover" placement="bottom">
              <div style="width: 200px">
                {{ $fanyi('您对该商品添加过的数据可以快捷填充，目前标签只支持：FBA，乐天，雅虎') }}
              </div>
              <div class="questionMarkIcon" slot="reference">?</div>
            </el-popover>
          </li>
          <li class="tagOperation" style="justify-content: center">{{ $fanyi("操作") }}</li>
        </ul>
      </div>
      <!-- 单个商品 -->
      <div class="optionFooter">
        <ul v-for="(listItem, listIndex) in list" :key="listIndex" class="flexAndCenter color333"
            style="background: transparent">
          <li class="tagType">
            <el-select v-model="listItem.type" size="small" style="width:160px" @change="updateTypeTranslate(listItem)">
              <el-option
                  v-for="(item,index) in listItem.list"
                  :key="index"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li class="tagEncode">
            <el-input v-model="listItem.no"
                      :placeholder="listItem.type==='YAHU'?'検品コード':listItem.type==='FBA'||listItem.type==='ZOZO'?'FNSKU':'SKU'"
                      size="small"
                      style="width: 220px;"/>
          </li>
          <li class="tagEncode">
            <el-input v-model="listItem.goods_no" style="width: 220px;" size="small"
                      :placeholder="listItem.type==='FBA'||listItem.type==='ZOZO'?'ASIN':'商品URL'"/>
          </li>
          <li class="oldTag flexAndCenterAndCenter">
            <div class="oldTagBtn" @click="getOldTagList(listIndex)">{{ $fanyi("历史标签") }}</div>
          </li>
          <li class="tagOperation" style="line-height: normal">
            <div v-if="isShow==true" style="margin-right: 20px"
                 @click="getBindInventoryList(listIndex)">
              {{ $fanyi("绑定亚马逊商品") }}
            </div>
            <div @click="list.splice(listIndex,1)">{{ $fanyi("删除") }}</div>
          </li>
        </ul>
      </div>
      <div class="addAttributeText fontSize14 flexAndCenter"
           @click="addTag"
           style="cursor: pointer">
        <img src="../../../../../../assets/5.png" alt="" style="width:16px;margin-right:7px">
        {{ $fanyi("添加一行") }}
      </div>
      <div class="newBtnList">
        <div @click="$parent.$parent.selectTagDialogVisible = false">{{ $fanyi('取消') }}</div>
        <div @click="saveGoodsTag">{{ $fanyi('保存') }}</div>
      </div>
    </div>
    <div class="dialogContainer">
      <!--    tag编辑-->
      <el-dialog
          width="656px"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          append-to-body
          custom-class="oldTagDialogContainer"
          :title="$fanyi('历史标签')"
          :visible.sync="oldTagDialogVisible">
        <div class="selectTagBtnContainer flexAndCenter">
          <div :class="tagCheckedIndex===0?'checkedTagTypeContainer':''" style="margin-right:8px"
               @click="tagCheckedIndex=0">アマゾン倉庫
          </div>
          <div :class="tagCheckedIndex===1?'checkedTagTypeContainer':''" style="margin-right:8px"
               @click="tagCheckedIndex=1">楽天倉庫
          </div>
          <div :class="tagCheckedIndex===2?'checkedTagTypeContainer':''" @click="tagCheckedIndex=2"
               style="margin-right:8px">ヤマト倉庫
          </div>
          <div :class="tagCheckedIndex===3?'checkedTagTypeContainer':''" @click="tagCheckedIndex=3">その他</div>
        </div>
        <div>
          <div class="oldTagTableHeader flexAndCenter">
            <div class="tagType">{{ $fanyi("标签号") }}</div>
            <div :class="tagCheckedIndex===0?'tagType':'goodsName'">{{ $fanyi("商品码") }}</div>
            <div v-show="tagCheckedIndex===0" class="goodsName">{{ $fanyi("标签信息") }}</div>
            <div class="selectTag">{{ $fanyi("操作") }}</div>
          </div>
          <div class="oldTagTableFooter" v-show="tagCheckedIndex===0">
            <div v-for="(item,index) in FBAList" :key="index" class="oldTagTableFooterLi flexAndCenter">
              <div class="tagType">{{ item.no }}</div>
              <div :class="tagCheckedIndex===0?'tagType':'goodsName'">{{ item.goods_no }}</div>
              <div v-show="tagCheckedIndex===0" class="goodsName">{{ item.text_line_one }}</div>
              <div class="selectTag flexAndCenter">
                <div @click="FBAAffirm(index)">{{ $fanyi("选择") }}</div>
              </div>
            </div>
          </div>
          <div class="oldTagTableFooter" v-show="tagCheckedIndex===1">
            <div v-for="(item,index) in EMSSKUList" :key="index" class="oldTagTableFooterLi flexAndCenter">
              <div class="tagType">{{ item.no }}</div>
              <div class="goodsName">{{ item.goods_no }}</div>
              <div class="selectTag flexAndCenter">
                <div @click="EMSSKUAffirm(index)">{{ $fanyi("选择") }}</div>
              </div>
            </div>
          </div>
          <div class="oldTagTableFooter" v-show="tagCheckedIndex===2">
            <div v-for="(item,index) in YAHUList" :key="index" class="oldTagTableFooterLi flexAndCenter">
              <div class="tagType">{{ item.no }}</div>
              <div class="goodsName">{{ item.goods_no }}</div>
              <div class="selectTag flexAndCenter">
                <div @click="YAHUAffirm(index)">{{ $fanyi("选择") }}</div>
              </div>
            </div>
          </div>
          <div class="oldTagTableFooter" v-show="tagCheckedIndex===3">
            <div v-for="(item,index) in otherList" :key="index" class="oldTagTableFooterLi flexAndCenter">
              <div class="tagType">{{ item.no }}</div>
              <div class="goodsName">{{ item.goods_no }}</div>
              <div class="selectTag flexAndCenter">
                <div @click="otherAffirm(index)">{{ $fanyi("选择") }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
      <!--    亚马逊补货商品信息-->
      <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :title="$fanyi('亚马逊补货商品信息')"
          :visible.sync="bindInventoryDialogVisible"
          append-to-body
          custom-class="oldTagDialogContainer"
          width="656px">
        <div>
          <div class="bindInventoryHeader">
            <el-input v-model="bindInventoryFrom.commodity_title" :placeholder="$fanyi('商品名称')"
                      style="width: 200px;margin-right: 10px"/>
            <el-input v-model="bindInventoryFrom.no" placeholder="ASIN/FNSKU" style="width: 200px;margin-right: 10px"/>
            <el-button type="primary">{{ $fanyi('搜索') }}</el-button>
          </div>
          <div class="oldTagTableHeader flexAndCenter">
            <div class="tagType">{{ $fanyi("照片") }}</div>
            <div class="goodsName">{{ $fanyi("商品名称") }}</div>
            <div class="tagType">SKU</div>
            <div class="tagType">ASIN/FNSKU</div>
            <div class="selectTag">{{ $fanyi("操作") }}</div>
          </div>
          <div class="oldTagTableFooter">
            <div v-for="(item,index) in bindInventoryList" :key="index" class="oldTagTableFooterLi flexAndCenter"
                 style="height: 80px">
              <div class="tagType">
                <el-popover placement="right" trigger="hover">
                  <img :src="item.link" alt="" style="width: 300px;height: 300px">
                  <img slot="reference" :src="item.link" alt="" style="width: 60px;height: 60px">
                </el-popover>
              </div>
              <div class="goodsName">{{ item.item_name }}</div>
              <div class="tagType">{{ item.fn_sku }}</div>
              <div class="tagType">{{ item.asin }}</div>
              <div class="selectTag flexAndCenter" @click="selectBindInventory(index)">
                <div>{{ $fanyi("选择") }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "editTag",
  props: {
    list: {//tag集合
      type: Array,
      default: []
    },
    timer: {//刷新子组件
      type: String
    },
    goodsInfo: {//商品数据
      type: Object
    },
    isShow: {//是否展示绑定亚马逊商品
      type: Boolean
    },
    fullscreenLoading: {//是否加载
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value: null,
      tagIndex: 0,
      oldTagDialogVisible: false,
      bindInventoryDialogVisible: false,
      EMSSKUList: [],
      tagCheckedIndex: 0,
      bindInventoryFrom: {
        commodity_title: '',
        no: ''
      },
      FBAList: [],
      bindInventoryList: [],
      YAHUList: [],
      otherList: []
    }
  },
  methods: {
    //保存商品的标签编号
    saveGoodsTag() {
      this.$emit("saveGoodsTag", this.list);
    },
    //获取历史标签
    getOldTagList(val) {
      this.tagIndex = val;
      this.$api.goodsTagLogs({
        goods_id: this.goodsInfo.goods_id,
        shop_type: this.goodsInfo.from_platform
      }).then((res) => {
        this.EMSSKUList = [];
        this.FBAList = [];
        this.YAHUList = [];
        this.otherList = [];
        res.data.forEach((item) => {
          switch (item.type) {
            case 'FBA':
              this.FBAList.push(item);
              break;
            case 'LETIAN':
              this.EMSSKUList.push(item);
              break;
            case 'YAHU':
              this.YAHUList.push(item);
              break;
            case '其他':
              this.otherList.push(item);
              break;
          }
        })
        this.oldTagDialogVisible = true
      });
    },
    //EMSSKU选择
    EMSSKUAffirm(index) {
      this.list.forEach((item, itemIndex) => {
        if (itemIndex === this.tagIndex) {
          item.no = this.EMSSKUList[index].no;
          item.goods_no = this.EMSSKUList[index].goods_no;
        }
      })
    },
    //更新type_translate
    updateTypeTranslate(item) {
      for (let i = 0; i < item.list.length; i++) {
        if (item.type === item.list[i].value) {
          item.type_translate = item.list[i].label
          break;
        }
      }
    },
    //向tag列表种加一行
    addTag() {
      this.list.push({
        type: 'FBA',
        no: '',
        goods_no: '',
        list: [{value: 'FBA', label: 'アマゾン倉庫'}, {value: 'LETIAN', label: '楽天倉庫'}, {
          value: 'YAHU',
          label: 'ヤマト倉庫'
        }, {value: '其他', label: 'その他'}, {value: 'ZOZO', label: 'ZOZO倉庫'}]
      })
    },
    //FBA选择
    FBAAffirm(index) {
      this.list.forEach((item, itemIndex) => {
        if (itemIndex === this.tagIndex) {
          item.no = this.FBAList[index].no;
          item.goods_no = this.FBAList[index].goods_no;
          this.oldTagDialogVisible = false;
        }
      })
    },
    //YAHU选择
    YAHUAffirm(index) {
      this.list.forEach((item, itemIndex) => {
        if (itemIndex === this.tagIndex) {
          item.no = this.YAHUList[index].no;
          item.goods_no = this.YAHUList[index].goods_no;
          this.oldTagDialogVisible = false;
        }
      })
    },
    //其他选择
    otherAffirm(index) {
      this.list.forEach((item, itemIndex) => {
        if (itemIndex === this.tagIndex) {
          item.no = this.otherList[index].no;
          item.goods_no = this.otherList[index].goods_no;
          this.oldTagDialogVisible = false;
        }
      })
    },
    //补货商品信息标签选择
    selectBindInventory(index) {
      this.list.forEach((item, itemIndex) => {
        if (itemIndex === this.tagIndex) {
          item.no = this.bindInventoryList[index].fn_sku;
          item.goods_no = this.bindInventoryList[index].asin;
          this.bindInventoryDialogVisible = false;
        }
      })
    },
    //获取入仓商品绑定
    getBindInventoryList(val) {
      this.tagIndex = val;
      this.$api.bindInventoryList(this.bindInventoryFrom).then((res) => {
        this.bindInventoryList = res.data;
        if (this.bindInventoryDialogVisible == false) {
          this.bindInventoryDialogVisible = true;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.editOrderTagContainer {

  .margin-top-40 {
    margin-top: 50px !important;

    /deep/ .el-input__inner {
      height: 30px;
      line-height: 30px;
    }

    /deep/ .el-input__icon {
      line-height: 30px;
    }
  }

  .selectTagBtnContainer {
    margin-bottom: 20px;

    div {
      width: 120px;
      height: 30px;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 3px;
      font-size: 14px;
      color: #333;
      cursor: pointer;
      line-height: 30px;
      text-align: center;
    }

    .checkedTagTypeContainer {
      color: #fff;
      background: #B4272B;
      border: 1px solid #B4272B;
    }
  }

  .tag {
    padding: 5px 10px;
    border: 1px solid;
    margin-right: 20px;
    color: #333;
    cursor: pointer;
  }

  .tagActive {
    background-color: #B4272B;
    color: #fff;
  }

  //.orderTagContainer {
  //  margin-top: 10px;
  //
  //  .label {
  //    margin-right: 10px;
  //    max-width: 102px;
  //    width: 102px;
  //  }
  //
  //  .oldTagContainer {
  //    div {
  //      width: 120px;
  //      height: 30px;
  //      background: #B4272B;
  //      border-radius: 3px;
  //      cursor: pointer;
  //      color: #fff;
  //      font-size: 14px;
  //      line-height: 30px;
  //      margin-right: 7px;
  //      text-align: center;
  //    }
  //
  //    img {
  //      width: 20px;
  //      height: 20px;
  //      flex-shrink: 0;
  //      margin-top: 10px;
  //    }
  //  }
  //}
  .optionHead {
    margin-top: 40px;

    ul {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background: #F0F0F0;
      height: 46px;

      li {
        text-align: center;
        line-height: 46px;
        font-size: 14px;
        color: #333;
      }
    }
  }

  .optionFooter {
    max-height: 550px;
    overflow-y: scroll;

    ul {
      height: 50px;
      border-bottom: 1px solid #DFDFDF;

      li {
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        color: #333;
      }
    }
  }

  .optionFooter::-webkit-scrollbar {
    width: 0;
  }

  .addAttributeText {
    margin-top: 12px;
    text-decoration: underline;
    color: #50C136;
    cursor: pointer;
  }

  .newBtnList {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      width: 120px;
      height: 34px;
      background: #FFFFFF;
      border: 1px solid #B4272B;
      border-radius: 8px;
      cursor: pointer;
      font-weight: 400;
      color: #B4272B;
      line-height: 34px;
      text-align: center;
    }

    div:first-child {
      margin-right: 26px;
    }

    div:last-child {
      background: #B4272B;
      color: #fff;
    }
  }

  .tagType {
    width: 150px;
    min-width: 150px;
    box-sizing: border-box;
  }

  .tagEncode {
    min-width: 260px !important;
    width: 260px !important;
    max-width: 260px !important;
    padding-left: 0 !important
  }

  .oldTag {
    width: 100px;

    .questionMarkIcon {
      width: 14px;
      height: 14px;
      border: 1px solid #B4272B;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #B4272B;
      font-size: 11px;
      cursor: pointer;
      margin-left: 6px;
    }

    .oldTagBtn {
      width: 120px;
      height: 30px;
      background: #B4272B;
      border-radius: 3px;
      line-height: 30px;
      text-align: center;
      color: #FFFFFF;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .tagOperation {
    width: 200px;
    display: flex;
    padding: 0 20px;

    div {
      color: #B4272B;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
      text-align: center;
    }
  }

  .dialog-footer {
    display: flex;
    margin-top: 30px;
    justify-content: center;

    /deep/ button {
      width: 120px;
      height: 34px;
      background: #B4272B;
      border-radius: 8px;
      line-height: 34px;
      padding: 0;
      text-align: center;
    }
  }
}
</style>
